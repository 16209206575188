import React from "react";
// import { Link } from "react-router-dom";
import logo from "ps-assets/img/logo.png";
import InstantBuyHeader from "components/Header/InstantBuyHeader.jsx";
import { Helmet } from "react-helmet";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import withStyles from "@material-ui/core/styles/withStyles";
import layoutStyle from "ps-assets/jss/layoutStyle.jsx";
import Parallax from "components/Parallax/Parallax";
import classNames from "classnames";
import PropTypes from "prop-types";
import Callback from "ps-components/Callback/Callback";
import { script3, addNoScript,addCorporateContacts } from 'util/LayoutUtil.jsx';
import Hidden from "@material-ui/core/Hidden";
import { browserDocument, browserLocalStorage, browserWindow } from "util/localStorageHelper";
const CUSTOMER_PORTAL_API_URL = process.env.CUSTOMER_PORTAL_API_URL;
class Layout extends React.Component {
  state = {
    active: 0,
    isLoaded: false,
    feedback: ``,
    autoPopup: 0,
    submitted: false,
    enableShake: false,
    loggedOut: false
  };

  img = [
    require(`assets/img/twinkle-logo.gif`),
    require(`assets/img/twinkle-close.png`)
  ];

  handleRemyClick = () => {
    browserDocument.getElementsByClassName(`wrap`)[0].classList.toggle(`active`);
    this.setState({
      active: this.state.active === 0 ? 1 : 0,
      autoPopup: 0
    });
  };

  handleRemyClickWithoutFeedback = () => {
    browserDocument.getElementsByClassName(`wrap`)[0].classList.toggle(`active`);
    this.setState({
      active: this.state.active === 0 ? 1 : 0,
      autoPopup: 0
    });
  };

  handleLogOut = (token, userId) => {
    this.setState({
      loggedOut: true
    });
    browserLocalStorage.setItem(`loggedOut${token}`, true);
    browserLocalStorage.setItem(`sessionalive`, false);
    fetch(
      `${CUSTOMER_PORTAL_API_URL}/api/services/authentication/user/logout`,
      {
        method: `GET`,
        headers: {
          userId: userId
        }
      }
    ).then(res => {
      console.log(res);
    });
  };

  componentDidUpdate() {
    if (this.state.submitted) {
      setTimeout(() => {
        this.setState({
          submitted: false
        });
      }, 1500);
    } else {
      this.state.enableShake &&
        setTimeout(() => {
          this.setState({
            enableShake: false
          });
        }, 500);
    }

    let authToken = ``;
    const searchString = browserWindow.location.search;
    const req = searchString && searchString.split(`?`)[1];
    const paramJson = {};
    req &&
      req.split(`&`).map(e => {
        const p = e && e.split(`=`);
        paramJson[p[0]] = p[1];
      });
    let { token, userId } = paramJson;
    authToken = token;
    if (authToken) {
      let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
      if (!logOutToken) {
        fetch(
          `${CUSTOMER_PORTAL_API_URL}/api/services/authentication/user/loginstatus`,
          {
            method: `GET`,
            headers: {
              userId: userId,
              Authorization: `Bearer ${authToken}`
            }
          }
        ).then(async res => {
          let response = await res.json();
          if (response.message === `invalid token`) {
            browserLocalStorage.setItem(`loggedOut${authToken}`, true);
            this.setState({
              loggedOut: true
            });
          }
        });
      }
    } else {
      let user = browserLocalStorage.getItem(`user`);
      if (user) {
        user = JSON.parse(user);
        authToken = user.token;
        userId = user.userId;
        if (authToken) {
          let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
          if (!logOutToken) {
            fetch(
              `${CUSTOMER_PORTAL_API_URL}/api/services/authentication/user/loginstatus`,
              {
                method: `GET`,
                headers: {
                  userId: userId
                }
              }
            ).then(async res => {
              let response = await res.json();
              if (response.message === `invalid token`) {
                browserLocalStorage.setItem(`loggedOut${authToken}`, true);
                this.setState({
                  loggedOut: true
                });
              }
            });
          }
        }
      }
    }
  }

  componentWillMount(){
    // addAnalyticsScript();
    var zoho_timer = setInterval(() => {
      let zoho_sales_iq = browserDocument.getElementById(`zsiq_float`);
      if(zoho_sales_iq && zoho_sales_iq.style){
        try{
          zoho_sales_iq.style.display = `none`;
          clearInterval(zoho_timer);
        }
        catch{
          console.log(`zoho sales exception`);
        }
      }
    });
  }

  componentDidMount() {
    addCorporateContacts();
    script3();
    addNoScript();
    let Attempts = 0;
    let checkLC = setInterval(() => {
      console.log(`waiting....`);
      Attempts++;
      if (browserWindow.LC_API) {
        console.log(`got LC API`);
        browserWindow.LC_API.on_chat_window_minimized = function() {
          console.log(`on_chat_window_minimized`);
          browserDocument.getElementById(
            `chat-widget-container`
          ).style.display = `none`;
          browserLocalStorage.setItem(`escalate`, false);
        };
        clearInterval(checkLC);
      } else {
        if (Attempts == 30) {
          console.log(`Max limit reached`);
          clearInterval(checkLC);
        }
      }
    }, 500);

    browserWindow.addEventListener(`storage`, e => {
      console.log(
        `The ` +
          e.key +
          ` key has been changed from ` +
          e.oldValue +
          ` to ` +
          e.newValue +
          `.`
      );
      if (e.key == `escalate`) {
        if (e.newValue == `true`) {
          this.handleRemyClickWithoutFeedback();
          browserDocument.getElementById(
            `chat-widget-container`
          ).style.display = `block`;
          if (browserWindow.LC_API) {
            browserWindow.LC_API.open_chat_window();
          } else {
            let Attempts = 0;
            let checkLC = setInterval(() => {
              Attempts++;
              if (browserWindow.LC_API) {
                console.log(`got LC API`);
                browserWindow.LC_API.open_chat_window();
                clearInterval(checkLC);
              } else {
                if (Attempts == 30) {
                  console.log(`Max limit reached`);
                  clearInterval(checkLC);
                }
              }
            }, 500);
          }
        }
      }
      if (e.key == `active`) {
        this.handleRemyClick();
      }
    });

    if (browserDocument.readyState === `complete`) {
      this.setState({
        isLoaded: true
      });
    }
    browserWindow.addEventListener(`load`, () => {
      console.log(`inside load`);
      if (browserDocument.readyState === `complete`) {
        this.setState({
          isLoaded: true
        });
        setTimeout(() => {
          this.setState({ autoPopup: 1 });
        }, 5000);
      }
    });
  }

  helmetNotInChild = children => {
    for (var key in children) {
      if (children.hasOwnProperty(key) && children[key] != null) {
        if (children[key].hasOwnProperty(`key`))
          if (children[key].key) {
            if (children[key].key == `helmetTags`) {
              return false;
            }
          }
      }
    }
    return true;
  };

  render() {
    console.log(`state - `, JSON.stringify(this.state));
    browserLocalStorage.setItem(`active`, this.state.active);
    const {
      children,
      classes,
      image1,
      image2,
      small,
      title,
      summary,
      noParallax,
      formatBanner,
      noFooter,
      noHeader
    } = this.props;
    let url = process.env.BASE_URL;
    let authToken = ``;
    const searchString = browserWindow.location.search;
    const req = searchString && searchString.split(`?`)[1];
    const paramJson = {};
    req &&
      req.split(`&`).map(e => {
        const p = e && e.split(`=`);
        paramJson[p[0]] = p[1];
      });
    let { token, userId } = paramJson;
    Array.isArray(userId);
    authToken = token;
    if (authToken) {
      browserLocalStorage.setItem(`user`, JSON.stringify(paramJson));
      let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
      if (logOutToken) {
        authToken = ``;
        userId = ``;
      }
    } else {
      let user = browserLocalStorage.getItem(`user`);
      if (user) {
        console.log(`user - `, user);
        user = JSON.parse(user);
        authToken = user.token;
        userId = user.userId;
        if (authToken) {
          let logOutToken = browserLocalStorage.getItem(`loggedOut${authToken}`);
          if (logOutToken) {
            authToken = ``;
            userId = ``;
          }
        }
      }
    }

    if (this.state.loggedOut) {
      authToken = ``;
      userId = ``;
    }

    return (
      <>
        <Helmet defer={false}>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
          <link
            rel="shortcut icon"
            href={require(`images/star-icon.png`)}
            type="image/png"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Roboto:400,100italic,100,300italic,300,500,400italic"
            rel="stylesheet"
            type="text/css"
          />
        </Helmet>
        {!noHeader && 
        <>
        <InstantBuyHeader
          key={1}
          className="header1"
          brand={<a href="/" target="_self"><img src={logo} className={classes.brand} alt="Star Health" /></a>}
          sticky
          color="white"
          changeColorOnScroll={{
            height: 100,
            color: `white`
          }}
        />
        <div style={{ backgroundColor: `red`, height: `50px` }}></div>
        <div className={classes.instantHeaderHeight} />
        </>}
        {!noParallax && (
          <Parallax
            image={image1}
            onHoverImage={image2}
            filter="dark"
            small={small}
            transition={true}
            layoutClass={formatBanner}
          >
            <Hidden implementation="css" smDown>
              <div
                className={
                  formatBanner
                    ? classNames(classes.container, classes.titlePadding)
                    : classes.container
                }
              >
                <GridContainer>
                  <GridItem
                    md={8}
                    sm={12}
                    className={classNames(
                      formatBanner
                        ? classes.mrAuto
                        : classNames(
                          classes.mlAuto,
                          classes.mrAuto,
                          classes.textCenter
                        )
                    )}
                  >
                    <div>
                      <h2
                        className={
                          formatBanner
                            ? classes.customPageTitle
                            : classes.pageTitle
                        }
                      >
                        {title}
                      </h2>
                      <h4 className={classes.summary}>{summary}</h4>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </Hidden>
          </Parallax>
        )}
        {children}
        {!browserWindow.location.href.includes(`#agent`) && <Callback />}
        {process.env.ENABLE_HELMET == `true` &&
          this.helmetNotInChild(children) && (
          <Helmet>
            <title>
                Star Health Insurance: Medical, Accident and Travel insurance policies
            </title>
            <script
              src="https://use.fontawesome.com/releases/v5.15.1/js/all.js"
              data-auto-a11y="true"
            ></script>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="MobileOptimized" content="width" />
            <link rel="canonical" href={url} />
            <meta
              property="og:image"
              content={require(`ps-assets/img/logo.png`)}
            />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="400" />
            <meta property="og:image:height" content="300" />
            <meta name="theme-color" content="#344985" />
            <meta
              name="title"
              content="Health Insurance, Buy Health Insurance, Online Insurance, Best Health Insurance"
            />
            <meta
              name="twitter:title"
              content="Health Insurance, Buy Health Insurance, Online Insurance, Best Health Insurance"
            />
            <meta
              name="description"
              content="Buy the best health insurance policy online that includes most of your medical expenses. Avail cashless treatment at 9800+ hospitals across India. Click to get a free health insurance quote and buy policy online."
            />
            <meta property="og:title" content="Health Insurance, Buy Health Insurance, Online Insurance, Best Health Insurance" />
            <meta property="og:description" content="Buy the best health insurance policy online that includes most of your medical expenses. Avail cashless treatment at 9800+ hospitals across India. Click to get a free health insurance quote and buy policy online." />
            <meta property="og:url" content={url}/>
            <meta name="twitter:site" content="@Starhealth" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="Health Insurance, Buy Health Insurance, Online Insurance, Best Health Insurance" />
            <meta
              httpEquiv="Content-Type"
              content="text/html; charset=utf-8"
            />
            <meta name="generator" content="strapi" />
            <meta property="dcterms.type" content="Text" />
            <meta property="dcterms.format" content="text/html" />
            <meta
              property="og:site_name"
              content="Star Health and Allied Insurance"
            />
            <meta property="og:type" content="website" />
            <meta property="dcterms.identifier" content={url} />
            <meta
              name="keywords"
              content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew Policy Online, Health Insurance Specialist In India"
            />
            <meta name="robots" content="follow, index" />
            <meta
              property="dcterms.title"
              content="Star Health Insurance, Buy Online Insurance, Best Health Insurance"
            />
            <meta
              property="og:title"
              content="Star Health Insurance, Buy Online Insurance, Best Health Insurance"
            />
          </Helmet>
        )}

        {!noFooter && 
        <div
          style={{
            minHeight: `200px`,
            background: `radial-gradient(at center center, rgb(88, 88, 88) 0px, rgb(35, 35, 35) 100%)`,
            color: `white`,
            textAlign: `center`,
            paddingTop: `20px`,
            paddingBottom: `20px`,
            fontSize: `0.8em`,
            textTransform: `capitalize`,
            fontWeight: 400,
            lineHeight: `1.43`
          }}
        >
          <div className={classNames(classes.grid, classes.instantBuyFooter)}>
            <GridItem
              md={8}
              style={{ margin: `auto` }}
              className={classes.instantBuyFooterContent}
            >
              REGISTERED & CORPORATE OFFICE: NO: 1, NEW TANK STREET,
              VALLUVARKOTTAM HIGH ROAD, NUNGAMBAKKAM, CHENNAI - 600034.
              <br />
              CIN NO: L66010TN2005PLC056649. IRDAI REGISTRATION NO: 129.
              INSURANCE IS THE SUBJECT MATTER OF SOLICITATION.
              <br />
              FOR MORE DETAILS ON RISK FACTORS, TERMS AND CONDITIONS PLEASE
              <br />
              READ THE SALES BROCHURE CAREFULLY BEFORE CONCLUDING A SALE.
              <br />
              IRDAI CLARIFIES TO PUBLIC THAT
              <ul style={{ textAlign: `left` }}>
                <li>
                  IRDAI OR ITS OFFICIALS DO NOT INVOLVE IN ACTIVITIES LIKE SALE
                  OF ANY KIND OF INSURANCE OR FINANCIAL PRODUCTS NOR INVEST
                  PREMIUMS.
                </li>
                <li>IRDAI DOES NOT ANNOUNCE ANY BONUS.</li>
                <li>
                  PUBLIC RECEIVING SUCH PHONE CALLS ARE REQUESTED TO LODGE A
                  POLICE COMPLAINT ALONG WITH DETAILS OF PHONE CALL, NUMBER.
                </li>
                <li>SHAI/17-18/Online/LP/BuyOnline/001</li>
              </ul>
              <br />
              Copyright ©2017. Star Insurance. All rights reserved
              <br />
            </GridItem>
          </div>
        </div>
        }
      </>
    );
  }
}

Layout.defaultProps = {
  noFooter: false,
  noHeader: false
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classes: PropTypes.object,
  image1: PropTypes.object,
  image2: PropTypes.object,
  small: PropTypes.bool,
  noParallax: PropTypes.bool,
  title: PropTypes.string,
  summary: PropTypes.string,
  formatBanner: PropTypes.string,
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool
};

export default withStyles(layoutStyle)(Layout);
