import {
  container,
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  transition,
  boxShadow,
  drawerWidth,
  blackColor,
  whiteColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.jsx";

import {showExtendedMenu} from "./headerLinksStyle";

const headerStyle = theme => ({
  appBar: {
    display: "flex",
    border: "0",
    // borderRadius: "3px",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: grayColor[15],
    width: "100%",
    backgroundColor: whiteColor,
    boxShadow:
      "0 4px 18px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 7px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: "relative"
  },
  absolute: {
    position: "absolute"
  },
  containernew:{
    paddingLeft: '0px !important',
    paddingRight: '0px !important'
  },
  extendHeaderDefault:{
    display:`none`
  },
  extendHeaderScrolled:{
    minHeight: "350px !important",
  },
  custHeaderDefault:{
    position: "fixed",
    top:50,
    [theme.breakpoints.down("md")]: {
      top: 0
    }
  },
  custHeaderScrolled:{
    position:"fixed",
    top:0
  },
  topNavDefault:{
    top:0,
    position:`fixed`
  },
  topNavScrolled:{
    top:0
  },
  justFixed: {
    position: "fixed",
  },
  fixed: {
    position: "fixed",
    top: `65px`,
    [theme.breakpoints.down("md")]: {
      top: 0
    }
  },
  topNav: {
    position: "fixed",
    top: 40
  },
  customBackgroundColor: {
    backgroundColor: `#E2E5EE`
  },
  topNav1: {
    position: "fixed",
    top: "0 !important" 
  },
  topNavExtended:{
    top:`275px`
  },
  customHeaderExtended:{
    top:`315px`
  },
  setMaxHeight: {
    maxHeight: "40px !important"
  },
  showExtendedMenu,
  extendedHeaderMaxHeight:{
    maxHeight: "25px !important"
  },
  enableBoxShadow: {
    boxShadow:
      "0 4px 18px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 7px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.15) !important"
  },
  disabled: {
    display: "none"
  },
  container: {
    ...container,
    minHeight: "40px",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap"
  },
  title: {
    "&,& a": {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        paddingRight: "2px"
      },
      ...defaultFont,
      minWidth: "unset",
      lineHeight: "30px",
      fontSize: "18px",
      borderRadius: "3px",
      textTransform: "none",
      whiteSpace: "nowrap",
      color: "inherit",
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent"
      }
    }
  },
  appResponsive: {
    margin: "20px 10px",
    marginTop: "30px"
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor
    // boxShadow:
    //   "0 4px 20px 0px rgba(" +
    //   hexToRgb(blackColor) +
    //   ", 0.14), 0 7px 12px -5px rgba(" +
    //   hexToRgb(primaryColor[0]) +
    //   ", 0.46)"
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(infoColor[0]) +
      ", 0.46)"
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(successColor[0]) +
      ", 0.46)"
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.46)"
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(dangerColor[0]) +
      ", 0.46)"
  },
  rose: {
    backgroundColor: roseColor[0],
    color: whiteColor,
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(roseColor[0]) +
      ", 0.46)"
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    // paddingTop: "25px",
    color: whiteColor
  },
  dark: {
    color: whiteColor,
    backgroundColor: grayColor[9] + " !important",
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 12px -5px rgba(" +
      hexToRgb(grayColor[9]) +
      ", 0.46)"
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: grayColor[15],
    backgroundColor: whiteColor + " !important"
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "scroll",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    paddingBottom: "40px",
    /* width */
    "&::-webkit-scrollbar": {
      width: "7px"
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px"
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: grayColor[10],
      borderRadius: "10px"
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: grayColor[28]
    },
    ...transition
  },
  hidden: {
    width: "100%"
  },
  collapse: {
    [theme.breakpoints.up("lg")]: {
      display: "flex !important",
      MsFlexPreferredSize: "auto",
      flexBasis: "auto"
    },
    WebkitBoxFlex: "1",
    MsFlexPositive: "1",
    flexGrow: "1",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center"
  },
  closeButtonDrawer: {
    position: "absolute",
    right: "8px",
    top: "9px",
    zIndex: "1"
  },
  contact: {
    color: "#35488a",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    rowGap: "30%",
    fontSize: "14px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px"
    }
  },
  contactContainer: {
    "@media (min-width: 767px)": {
      display: "flex",
      flexDirection: "row",
    }
  },
  icon: {
    [theme.breakpoints.down("sm")]: {
      width: "15px"
    }
  },
  drawer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2px"
    }
  },
  customModal: {
    zIndex: 9999999999
  }
});

export default headerStyle;
