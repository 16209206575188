import {
  mlAuto,
  mrAuto,
  mBAuto,
  mTAuto,
} from 'assets/jss/material-kit-pro-react.jsx';

const GmcAllPoliciesStyle = function() {
  return {
    mlAuto,
    mrAuto,
    mBAuto,
    mTAuto,

    list: { width: `250` },
    fullList: { width: `auto` },
    titledivstyle: {
      width: `50%`,
      float: `left`,
      paddingLeft: `13%`,
      '@media (max-width: 768px)': {
        width: `100%`,
        paddingLeft: `0%`,
      },
    },
    backgroundImage: {
      width: `40%`,
      float: `left`,
      height: `100%`,
      '@media (max-width: 768px)': {
        width: `100%`,
      },
      '@media (max-width: 500px)': {
        width: `100%`,
        height:'63%'
      },
      '@media (max-width: 400px)': {
        width: `100%`,
        height:'57%'
      },
      
    },
    headerdiv: {
      background: `#10bfee`,
      width: `100%`,
      border: `1px`,
      height: `200px`,
      borderBottom: `solid 1px #344985`,
    },
    titilestyle: {
      color: `white`,
      fontSize: `36px`,
      fontWeight: `600`,
      fontFamily: `inherit`,
      marginTop: `7%`,
      lineHeight: `37px`,
      textAlign: `left`,
      '@media (max-width: 992px)': {
        fontSize: `30px`,
      },
      '@media (max-width: 768px)': {
        fontSize: `35px`,
        marginTop: `5%`,
        width: `100%`,
        textAlign: `center`,
      },
      '@media (max-width: 400px)': {
        fontSize: `28px`,
        marginTop: `5%`,
        width: `100%`,
        opacity:'1',
        paddingLeft:'3%',
        paddingRight:'3%',
        fontWeight:'500',
        textAlign: `center`,
      },
    },
    logoPositionStyle:{
      width:'100%',
      '@media (max-width: 500px)': {
        width: '89%',
    paddingBottom: '3%'
      },
    
    },
    tollfreeposition:{
    fontSize: '11px',
    color: `#969696`,
    width:'100%',
    marginTop: `32px`,
    marginLeft: `114px`,
    display: `inline-flex`,
    '@media (max-width: 580px)': {
      marginLeft: `0px`,
      marginTop: `8px`,
    },
    },
    headerPosition:{
    paddingTop: '1.5%',
    paddingLeft: '8%',
    paddingBottom: '1.5% ',
    display:'flex',
    '@media (max-width: 580px)': {
      display:'block',
    },
    },
    subtitle: {
      color: `white`,
      fontSize: `18px`,
      fontWeight: `300`,
      marginTop: `10px`,
      lineHeight: `30px`,
      textAlign: `left`,
      '@media (max-width: 992px)': {
        fontSize: `25px`,
        // marginTop: '5%',
      },
      '@media (max-width: 768px)': {
        width: `100%`,
        textAlign: `center`,
      },
      '@media (max-width: 500px)': {
        fontSize: '18px',
        marginTop: '4%',
        fontWeight: '400',
        marginBottom: '3%'
      },
      
    },
    list1divstyle: {
        width: '100%',
        height: 'auto',
      background: `white`,
      paddingTop:'2%',
      paddingLeft: `12.4%`,
      paddingRight: `10%`,
      '@media (max-width: 768px)': {
        marginTop: `19%`,
        height: `209px`,
        paddingTop:'4%'
      },
      '@media (max-width: 620px)': {
        marginTop: `21%`,
        height: `260px`,
        paddingTop:'7%'
      },
      '@media (max-width: 551px)': {
        marginTop: `21%`,
        height: `242px`,
        paddingTop:'9%',
        paddingRight: `3%`,
      },
      '@media (max-width: 550px)': {
        marginTop: `21%`,
        height: `245px`,
        paddingTop:'9%',
        paddingLeft:'9%',
        paddingRight: `3%`,
      },
      list5header:{
        width: `100%`,
        marginLeft: `1%`,
        // paddingTop: `41px`,
        color: `#243880`,
        fontSize: `16px`,
        fontWeight: `800`,
        paddingBottom: `23px`,
        '@media (max-width: 770px)': {
          paddingBottom:'3px'
        },
        '@media (max-width: 550px)': {
          // paddingTop: `20px`,
          paddingBottom:'3px'
  
        },
        '@media (max-width: 500px)': {
          paddingTop: `20px`,
          paddingBottom:'3px'
  
        },
      },
      '@media (max-width: 478px)': {
        marginTop: `21%`,
        height: `316px`,
        paddingTop:'5%',
        paddingLeft:'9%',
        paddingRight: `3%`,
      },
      '@media (max-width: 438px)': {
        marginTop: `21%`,
        height: `279px`,
        paddingTop:'10%',
        paddingLeft:'9%',
        paddingRight: `3%`,
      },
      '@media (max-width: 400px)': {
        marginTop: `-6%`,
        height: `316px`,
        paddingLeft:'9%',
        paddingRight: `3%`,
        // paddingTop:'20%'
      },
      '@media (max-width: 379px)': {
        marginTop: `11%`,
        height: `288px`,
        paddingTop:'8%',
        paddingLeft:'9%',
        paddingRight: `3%`,
      },
      '@media (max-width: 374px)': {
        marginTop: `21%`,
        height: `288px`,
        paddingTop:'8%',
        paddingLeft:'9%',
        paddingRight: `3%`,
      },
      '@media (max-width: 350px)': {
        marginTop: `21%`,
        height: `281px`,
        paddingTop:'12%',
        paddingLeft:'9%',
        paddingRight: `3%`,
      },
      '@media (max-width: 325px)': {
        marginTop: `18%`,
        height: `331px`,
        paddingTop:'14%',
        paddingLeft:'9%',
        paddingRight: `3%`,
      },

    },
    icondiv: {
      float: `left`,
      width: `34px`,
      '@media (max-width: 768px)': {
        width: `34px`,
      },
    },
    list1header: {
      width: `100%`,
      marginLeft: `1%`,
      // paddingTop: `41px`,
      color: `#243880`,
      fontSize: `16px`,
      fontWeight: `800`,
      paddingBottom: `23px`,
      '@media (max-width: 770px)': {
        paddingBottom:'3px'
      },
      '@media (max-width: 550px)': {
        // paddingTop: `20px`,
        paddingBottom:'3px'

      },
      '@media (max-width: 500px)': {
        paddingTop: `20px`,
        paddingBottom:'3px'

      },
    },
    marginLeftAlign: {
      marginLeft: `3% !important`,
      lineHeight: `18px !important`,
      '@media (max-width: 768px)': {
        marginLeft: `0% !important`,
      },
    },
    marginLeftNotAlign: {
      marginLeft: `0% !important`,
      lineHeight: `18px !important`,
      '@media (max-width: 768px)': {
        marginTop: `8px !important`,
      },
    },
    liststyle: {
      width: `29.33%`,
      float: `left`,
      '@media (max-width: 768px)': {
        background: `white`,
        width: `100%`,
        paddingTop: `7px`,
      },
      '& p': {
        fontSize: `12px !important`,
        color: `#525252`,
        fontWeight: `600`,
        paddingRight: `17%`,
      },
    },
    anchorcolor: {
      color: `#969696 !important`,
    },
    anchorunderline: {
      '&:hover': {
        textDecoration: `underline`,
      },
    },
    unorderlist: {
      width: `100%`,
      background: `white`,
      marginTop: `0%`,
      listStyle: `none`,
      padding: `0px 0.1%`,
      height: `103px`,
      '@media (max-width: 718px)': {
        height: `280px`,
      },
      '@media (max-width: 456px)': {
        height: `350px`,
        marginLeft:'-1.7%'
      },
    },
    // divinput: {
    //   width: '100% ',
    //   height: '280px ',
    //   background: '#ebfbff',
    // },
    inputdivstyle: {
      width: `100% `,
      height: `402px `,
      background: `#ebfbff`,
      paddingTop: `-2%`,
      paddingLeft: `12.5%`,
      paddingRight: `12.5%`,
      '@media (max-width: 1202px)': {
        paddingLeft: `3%`,
        paddingRight: `3%`,
      },
      '@media (max-width:992px)': {
        height: ` 460px `,
        paddingLeft: `3%`,
        paddingRight: `3%`,
      },
      '@media (max-width: 768px)': {
        height: `100% `,
        paddingLeft: `3%`,
        paddingRight: `5%`,
        paddingBottom:`2%`
      },
      '@media (max-width: 600px)': {
        height: `100% `,
        paddingLeft: `6%`,
        paddingRight: `6%`,
        paddingBottom:`2%`
      },
    },
    inputheaderdiv: {
      width: `32%`,
      height: `74px`,
      float: `left`,
      marginTop: `-0.5%`,
      // float: 'left',
      marginLeft: `1%`,
      '@media (max-width: 992px)': {
        width: `49%`,
      },
      '@media (max-width: 768px)': {
        width: `100%`,
      },
    },
    alertdiv: {
      fontSize: `14px`,
      color: `red`,
      marginTop: `-4px`,
      paddingLeft: `3px`,
    },
    inputdiv: {
      // padding: '10px',
      // borderRadius: '5px',
      paddingTop: `7px`,

      width: `100%`,
      border: `1px solid #DFDFDF`,
      marginBottom: `7px`,
      boxShadow: `-2px 2px 0px 0px #efefef`,
      backgroundColor: ` #fff`,
    },
    privacystyle: {
      width: `65%`,
      height: `74px`,
      float: `left`,
      marginTop: `1.5%`,
      // float: 'left',
      marginLeft: `1%`,
      '@media (max-width: 992px)': {
        width: `49%`,
      },
      '@media (max-width: 768px)': {
        width: `100%`,
        height: `39px`,
      },
      '@media (max-width: 700px)': {
        width: `100%`,
        height: `55px`,
      },
      '@media (max-width: 480px)': {
        width: `100%`,
        height: `66px`,
      },
      '@media (max-width: 420px)': {
        width: `100%`,
        height: `49px`,
      },
      '@media (max-width: 361px)': {
        width: `100%`,
        height: `66px`,
      },      
    },
    privacytextdiv: {
      float: `left`,
      width: `58%`,
      // textAlign: 'right',
      fontSize: `11px`,
      color: `#999999`,
      lineHeight: `15px`,
      fontWeight: `400`,
      '@media (max-width: 768px)': {
        width: `82%`,
        lineHeight: `26px`,
        marginLeft: `1%`,
        textAlign:`left`
      },
      '@media (max-width: 610px)': {
        width: `76%`,
        lineHeight: `17px`,
        marginLeft: `4%`,
        textAlign:`left`
      },
      '@media (max-width: 560px)': {
        width: `75%`,
        lineHeight: `17px`,
        marginLeft: `4%`,
        textAlign:`left`
      },
      
      '@media (max-width: 450px)': {
        width: `87%`,
        lineHeight: `17px`,
        marginLeft: `4%`,
        textAlign:`left`
      },
      '@media (max-width: 400px)': {
        width: `87%`,
        lineHeight: `17px`,
        marginLeft: `7%`,
        textAlign:`left`
      },

      // '@media (max-width: 622px)': {
      //   marginTop: '-7.4vw',
      // },
      // '@media (max-width: 548px)': {
      //   marginTop: '-8vw',
      // },
      // '@media (max-width: 468px)': {
      //   marginTop: '-11.5vw',
      // },
      // '@media (max-width: 330px)': {
      //   marginTop: '-12.5vw',
      // },
    },
    checkboxdiv: {
      float: `left`,
      width: `42%`,
      textAlign: `right`,
      '@media (max-width: 768px)': {
        width: `3%`,
      },
    },
    submitdivGrid:{
      width: `100%`,
      textAlign: `right`,
      paddingRight: `4.2%`,
      marginLeft:`auto`,
      // marginRight:`-7%`,
      '@media (max-width: 768px)': {
        textAlign: `center`,
      },
      '@media (max-width: 500px)': {
        paddingTop: `0px`,
        marginLeft:`auto`,
        marginRight:`0%`
      },
    },
    submitPosition: {
      paddingLeft: `3%`,
      paddingRight: `3%`,
      paddingTop: `0.8%`,
      paddingBottom: `0.8%`,
      background: `#24386f`,
      color: `white`,
      fontWeight: `600`,
      fontSize: `13px`,
      borderRadius: `50px`,
      cursor: `pointer`,
      border: `3px solid #adadad`,
      '@media (max-width: 600px)': {
        paddingLeft: `35px`,
        paddingRight: `35px`,
        paddingTop: `10px`,
        paddingBottom: `10px`,
        fontSize: `15px`,
        marginTop:`1%`
      },
      '@media (max-width: 400px)': {
        paddingLeft: `35px`,
        paddingRight: `35px`,
        paddingTop: `10px`,
        paddingBottom: `10px`,
        fontSize: `15px`,
        marginTop:`2%`
      },
      // '@media (max-width: 399px)': {
      //   paddingLeft: `35px`,
      //   paddingRight: `35px`,
      //   paddingTop: `10px`,
      //   paddingBottom: `10px`,
      //   fontSize: `15px`,
      //   marginTop:`11%`
      // },
      '@media (max-width: 338px)': {
        paddingLeft: `35px`,
        paddingRight: `35px`,
        paddingTop: `10px`,
        paddingBottom: `10px`,
        fontSize: `15px`,
        marginTop:`2%`
      },


      
      
    },
    textfield: {
      marginTop: `-2.2%`,
      width: `70%`,
    },
    inputstyle: {
      backgroundColor: `transparent`,
      border: `0px solid`,
      width: `80%`,
      '@media (max-width: 770px)': {
        width:'48%'
      },
      '@media (max-width: 600px)': {
        width:'80%'
      },
      '@media (max-width: 400px)': {
        width:'71%'
      },
      '@media (max-width: 350px)': {
        width:'66%'
      },

      
      
    },
    inputcontainerstyle: {
      width: `100%`,
    },
    footer: {
      width: `100%`,
      height: `auto`,
      paddingLeft: `9.5%`,
      paddingRight: `10%`,
      color: `#969696`,
      background: `#eee !important`,
      // paddingBottom: '2.5%',
      '@media (max-width: 992px)': {
        background: `#eee`,
        height: `119px`,
        paddingLeft: `0%`,
      },
    },
    submitdiv: {
      width: `100%`,
      textAlign: `right`,
      paddingRight: `1.2%`,
      marginLeft:`auto`,
      marginRight:`0%`,
      '@media (max-width: 1025px)': {
        marginRight:`0%`,
      },
      '@media (max-width: 768px)': {
        textAlign: `center`,
      },
      '@media (max-width: 500px)': {
        paddingTop: `0px`,
        marginLeft:`auto`,
        marginRight:`0%`
      },
    },
    unorderliststyle: {
      listStyleType: `circle`,
      fontSize: `11px`,
      fontWeight: `400`,
      display: `flex`,
      color: `#969696`,
      // -webkit-flex-flow: wrap column, /* Safari 6.1+ */
      flexFlow: `wrap column`,
      maxHeight: `auto`,
    },
    list2style: {
      width: `100%`,
      // float: 'left',
      marginTop: `-1%`,
      color: `#969696`,
    },
    list1div: {
      width: `16%`,
      float: `left`,
      color: `#969696`,
      '@media (max-width: 768px)': {
        width: `48%`,
      },
    },
    list2div: {
      width: `15%`,
      float: `left`,
      color: `#969696`,
      '@media (max-width: 768px)': {
        width: `48%`,
      },
    },
    list3div: {
      width: `16%`,
      float: `left`,
      color: `#969696`,
    },
    list4div: {
      width: `27%`,
      float: `left`,
      color: `#969696`,
      // height: '400px',
    },
    list5div: {
      width: `23%`,
      float: `left`,
      color: `#969696`,
    },
    footerparagraphstyle: {
      width: `100%`,
      float: `none`,
      display: `inline-block`,
      fontSize: `12px`,
      paddingLeft: `2.1%`,
      // paddingTop: '2%',
      lineHeight: `16px`,
      fontWeight: `400`,
      color: `#969696`,
    },
    footerpaydiv1: {
      width: `48%`,
      float: `left`,
      marginTop: `1.35%`,
      paddingBottom: `3%`,
      '@media (max-width: 768px)': {
        width: `100%`,
      },
    },
    footerpaydiv3: {
      width: `48%`,
      // float: 'left',
      marginTop: `1.35%`,
      paddingBottom: `3%`,
      marginLeft: `1.5%`,
      '@media (max-width: 765px)': {
        width: `100%`,
        paddingBottom:`5.5%`
      },
    },
    footerpaydiv4: {
      width: `48%`,
      // float: 'left',
      marginTop: `1.35%`,
      paddingBottom: `3%`,
      marginLeft: `1.5%`,
      '@media (max-width: 768px)': {
        width: `100%`,
        paddingBottom: `5.5%`
      },
    },

    footerpaydiv2: {
      width: `48%`,
      float: `left`,
      textAlign: `right`,
      paddingBottom: `3%`,
      marginTop: `1.35%`,
      '@media (max-width: 768px)': {
        width: `100%`,
        textAlign: `left`,
      },
    },
    copyright: {
      // paddingTop: '2.5%',
      // visibility: 'hidden',
      position: `absolute`,
      marginLeft: `10%`,
      // '@media (max-width: 768px)': {
      // visibility: 'visible',
      // },
    },
    copyrightFooter: {
      width: `100%`,
      textAlign: `center`,
      fontSize: `11px`,
      color: `#868686`,
      fontFamily: `Roboto`,
      '@media (max-width: 768px)': {
        width: `100%`,
        textAlign: `left`,
        paddingLeft: `1.5%`,
        paddingBottom: `6.5%`,
        fontSize: '11px',
        color: '#868686',
        fontWeight: '400'
      },
    },
    topListStyle: {
      paddingLeft: `17px !important`,
      '& li': {
        display: `inline-block`,
        paddingLeft: `7px`,
        '& a': {
          color: `black`,
          fontSize: `11px`,
        },
      },
    },
    aboutWidth: {
      // marginTop: '9%',
      width: `264px`,
      paddingLeft: `0px !important`,
      paddingRight: `0px !important`,
      marginLeft: `16px`,
      marginRight: `16px`,
      borderBottom: `1px solid #dedede`,
      fontSize: `0.8rem`,
      '@media (max-width: 370px)': {
        width: `218px`,
        borderBottom: `1px solid #dedede`,
      },
    },
    searchWidth: {
      // marginTop: '9%',
      width: `264px`,
      marginTop: `4%`,
      paddingLeft: `0px !important`,
      paddingRight: `0px !important`,
      marginLeft: `16px`,
      fontSize: `0.8rem`,
      marginRight: `16px`,
      '@media (max-width: 370px)': {
        width: `218px`,
      },
    },
    tollfreeWidth: {
      width: `264px`,
      marginTop: `4%`,
      paddingLeft: `0px !important`,
      paddingRight: `0px !important`,
      marginLeft: `16px`,
      fontSize: `0.8rem`,
      marginRight: `16px`,
      '@media (max-width: 370px)': {
        width: `218px`,
      },
    },
    searchInput: {
      height: `30px`,
      width: `100%`,
      fontSize: `0.8rem`,
    },
    closeIconPosition: {
      width: `29px`,
      marginLeft: `80%`,
      marginTop: `4%`,
      '@media (max-width: 370px)': {
        marginLeft: `82%`,
        marginTop: `3%`,
      },
    },
    searchBox: {
      width: `200px`,
      height: `26px`,
      fontSize: `11px`,
      marginLeft: `24px`,
      marginRight: `2px`,
      padding: `7px`,
    },
    iconList: {
      '& a': {
        '& i': {
          fontSize: `14px`,
          marginLeft: `15px`,
        },
      },
    },
  };
};

export default GmcAllPoliciesStyle;
