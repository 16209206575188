import React from "react";

export default function CallComponent({ contactNumber, code,color }) {
  let finalContactNumber = "";
  if (code) {
    finalContactNumber = `${code} `;
  }
  finalContactNumber = `${finalContactNumber}${contactNumber}`;
  if (contactNumber == '+91-9597652225'){
    return <a style={{color:color ? color : '#35488a'}} target="blank" href={`https://wa.me/${finalContactNumber.replace("-","")}`}>{contactNumber}</a>;
  }
  else{
    return <a style={{color:color ? color : '#35488a'}} href={`tel:${finalContactNumber}`}>{contactNumber}</a>;
  }

}
