import React from 'react';
import PropTypes from 'prop-types';
import InstantBuyLayout from 'ps-components/Layout/InstantBuyLayout.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
// import Drawer from '@material-ui/core/Drawer';
import logo from 'ps-assets/img/allpolicies_logo.png';
import Hidden from '@material-ui/core/Hidden';
import headerImage from 'ps-assets/img/header_img.jpg';
import CallComponent from "components/CallComponent";
import americancard from 'ps-assets/img/american-express-curved-64px.png';
import maestrocard from 'ps-assets/img/maestro-curved-64px.png';
import mastercard from 'ps-assets/img/mastercard-curved-64px.png';
import visacard from 'ps-assets/img/visa-curved-64px.png';
// import menuIcon from 'ps-assets/img/allpolicymenu.png';
// import closeIcon from 'ps-assets/img/allpolicyclose.png';
import appstore from 'ps-assets/img/google-play.jpg';
import bbstore from 'ps-assets/img/bb-world.jpg';
import applestore from 'ps-assets/img/app-store.png';
import { landingPageLMSCall } from 'util/lmsRequest';
import root from 'window-or-global';
import alertIcon from 'ps-assets/img/alertIcon.png';

import gmcListicon from 'ps-assets/img/gmcListicon.png';
import icons1 from 'ps-assets/img/icons-for-allpolicies/icon1.png';
import icons2 from 'ps-assets/img/icons-for-allpolicies/icon2.png';
import icons3 from 'ps-assets/img/icons-for-allpolicies/icon3.png';
import icons4 from 'ps-assets/img/icons-for-allpolicies/icon4.png';
import icons5 from 'ps-assets/img/icons-for-allpolicies/icon5.png';
import icons6 from 'ps-assets/img/icons-for-allpolicies/icon6.png';
import icons7 from 'ps-assets/img/icons-for-allpolicies/icon7.png';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';

import GmcAllPoliciesStyle from 'ps-assets/jss/gmcAllPoliciesStyle.jsx';
// import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import {  browserWindow } from "util/localStorageHelper";

const list1 = [
  'Employees are the backbone of any organization.',
  'Your employees certainly deserve access to quality healthcare across the Country.',
  'Our Corporate Business Team will be happy to assist you in customizing the most optimum Health Insurance cover for the benefit of your employees and their dependents.',
];
const list2 = [
  ' IRDAI or its officials do not involve in activities like sale of any kind of insurance or financial products nor invest premiums.',
  'Public receiving such phone calls are requested to lodge a police complaint along with details of phone call, number.',
  ' IRDAI does not announce any bonus.',
];
const optionValue = ['7-100', '101-500', '501+'];
const validationConditions = {
  name: `^[a-zA-Z .]*$`,
  mobile: `^[6-9][0-9]{9}$`,
  email: `^[a-zA-Z0-9.!#$%&'*+/=?^_\`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$`,
  // company_name: `^[a-zA-Z .]*$`,
};
const linkname = {
  1: {
    name: 'Home',
    url: '/',
  },
  2: {
    name: 'Contact Us',
    url: '/contact-us',
  },
  3: {
    name: 'Careers',
    url: '/careers',
  },
  4: {
    name: 'FAQ',
    url: '/faq',
  },
  5: {
    name: 'Privacy',
    url: '/disclaimer#privacy_policy',
  },
  6: {
    name: 'Terms of Usage',
    url: '/disclaimer#terms_of_usage',
  },
  7: {
    name: 'Disclaimer',
    url: '/disclaimer#disclaimer',
  },
  8: {
    name: 'Safe Buying',
    url: '/disclaimer/safebuying',
  },
  9: {
    name: 'Sitemap',
    url: '/sitemap',
  },
  10: {
    name: 'Glossary',
    url: '/star-glossary',
  },
  11: {
    name: 'Anti Fraud Policy',
    url: '/antifraud-policy',
  },
  12: {
    name: 'Downloads',
    url: '/Download',
  },
  13: {
    name: 'Public Disclosure',
    url: '/public-disclosure',
  },
  14: {
    name: 'Grievance',
    url: '/grievance-redressal',
  },
  15: {
    name: 'GIC',
    url: 'https://www.gicofindia.com/index.php?lang=en',
    target: '_blank',
  },
  16: {
    name: 'IRDAI',
    url: 'https://www.irdai.gov.in/Defaulthome.aspx?page=H1',
    target: '_blank',
  },
  17: {
    name: 'Employee Login',
    url:
      'https://www.mail.starhealth.in/CookieAuth.dll?GetLogon?curl=Z2FowaZ2F&amp;reason=0&amp;formdir=5',
    target: '_blank',
  },
  18: {
    name: 'Lab Login',
    url: 'https://portal.starhealth.in/lab',
    target: '_blank',
  },
  19: {
    name: 'Corporate Health Claim',
    url: 'https://www.portal.starhealth.in/CorpClient/wfrm_Login.aspx',
    target: '_blank',
  },
  20: {
    name: 'Tax Benefits',
    url: '/tax-benefits',
  },
  21: {
    name: 'GIC Council &amp; Insurance Information Bureau',
    url: 'https://www.gicouncil.in/',
    target: '_blank',
  },
  22: {
    name: 'IRDAI Consumer Education website',
    url: 'https://www.policyholder.gov.in/',
    target: '_blank',
  },
  23: {
    name: 'Claim Help Desk',
    url: '/claims-helpdesk-page',
  },
  24: {
    name: 'Dengue Fever',
    url: '/dengue-fever',
  },
  25: {
    name: 'Free Look Period',
    url: '/free-look-period',
  },
  26: {
    name: 'Portability',
    url: '/portability',
  },
  27: {
    name: 'Unclaimed Amount',
    url: '/policies/claimProposal',
  },
  28: {
    name: 'Health Insurance for Corporate Clients',
    url: '/landing/gmc/allpolicies/index.html',
    target: '_blank',
  },
};

class GmcAllPolicies extends React.Component {
  state = {
    menuTop: false,
    No_of_Employees: ``,
    check: false,
    finalerror: false,
    user: {
      name: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please Enter Name`,
        error1: `Please Enter Name`,
        error2: `Name should be minimum 3 and maximum 50 character`,
      },
      mobile: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please enter valid Phone Number`,
        error1: `Please enter valid Phone Number`,
        error2: `Phone Number should be minimum 10 Digits`,
      },
      email: {
        mandatry: true,
        value: ``,
        error: true,
        errormsg: `Please enter correct Email ID and try again.`,
        error1: `Please enter correct Email ID and try again.`,
      },
      company_website: {
        mandatry: false,
        value: ``,
        error: false,
        errormsg: `Please enter valid CompanySite`,
        error1: `Please enter valid CompanySite`,
      },

      company_name: {
        mandatry: true,
        value: ``,
        other: ``,
        error: true,
        errormsg: `Please enter Company Name`,
        error1: `Please enter Company Name`,
      },
      industry: {
        mandatry: false,
        value: ``,
        other: ``,
        error: false,
        errormsg: `Please enter Company Name`,
        error1: `Please enter Company Name`,
      },
      no_of_employee: {
        mandatry: true,
        value: ``,
        other: ``,
        error: true,
        errormsg: `Please Choose No of Employees`,
        error1: `Please Choose No of Employees`,
      },
    },
  };
  onChange = (event) => {
    this.setState({ No_of_Employees: event.target.value });
  };
  basestate = {
    user: this.state.user,
  };
  handleChange(e) {
    let change = {};
    change = JSON.parse(JSON.stringify(this.state.user));
    if (e.target.name == `name`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (e.target.value.length < 3) {
          change[e.target.name].errormsg = change[e.target.name].error2;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        let value = e.target.value.replace(/[^A-Z a-z]/gi, ``);
        change[e.target.name].value = value;
      } else {
        change[e.target.name].value = e.target.value;
      }
    } else if (e.target.name == `email`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (
          e.target.value.search(validationConditions[e.target.name]) == -1
        ) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        change[e.target.name].value = e.target.value;
      } else {
        change[e.target.name].value = e.target.value;
      }
    } else if (e.target.name == `mobile`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (
          e.target.value.search(validationConditions[e.target.name]) == -1
        ) {
          change[e.target.name].errormsg = change[e.target.name].error2;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        let value = e.target.value.replace(/[^0-9]/gi, ``);
        change[e.target.name].value = value;
      } else {
        change[e.target.name].value = e.target.value;
      }
    } else if (e.target.name == `company_website`) {
      change[e.target.name].value = e.target.value;
    } else if (e.target.name == `company_name`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        change[e.target.name].value = e.target.value;
      } else {
        change[e.target.name].value = e.target.value;
      }
    } else if (e.target.name == `industry`) {
      change[e.target.name].value = e.target.value;
    } else if (e.target.name == `no_of_employee`) {
      if (change[e.target.name].mandatry == true) {
        if (e.target.value === ``) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else if (e.target.value == true) {
          change[e.target.name].errormsg = change[e.target.name].error1;
          change[e.target.name].error = true;
        } else {
          change[e.target.name].error = false;
        }
        change[e.target.name].value = e.target.value;
      } else {
        change[e.target.name].value = e.target.value;
      }
    }
    this.setState({
      user: change,
    });
  }
  handleCheck = () => {
    let check1 = !this.state.check;
    this.setState({
      check: check1,
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    let error = false;

    Object.keys(this.state.user).map((key) => {
      if (this.state.user[key].error && !error) {
        alert(this.state.user[key].errormsg);
        error = true;
        return;
      }
    });
    if (this.state.check == false && error == false) {
      //   alert(`Read Terms and Conditions`);
      error = true;
      return;
    }
    if (error == false) {
      let queryParams = decodeURIComponent(browserWindow.location.search);
      queryParams = queryParams.split(`?`)[1];
      let response = await landingPageLMSCall(
        this.state.user.name.value,
        this.state.user.mobile.value,
        this.state.user.email.value,
        ``,
        null,
        {
          company_name: this.state.user.company_name.value,
          company_website: this.state.user.company_website.value,
          industry: this.state.user.industry.value,
          no_of_employee: this.state.user.no_of_employee.value,
        }
      );
      response = await response.json();
      sessionStorage.setItem(`prevLandingPage`, `free-health-quote`);
      root.open(
        `${browserWindow.location.pathname}/thank-you?userId=${response.zohoEntityId}${
          queryParams ? `&${queryParams}` : ``
        }`,
        `_self`
      );
      // alert('success');
    }
  };
  toggleDrawer = (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    let val = !this.state.menuTop;

    this.setState({ menuTop: val });
  };

  render() {
    const { classes } = this.props;
    // const list = (
    //   <div
    //     className={`${classes.list} ${classes.fullList}`}
    //     role='presentation'
    //     // onClick={this.toggleDrawer.bind(this)}
    //     onKeyDown={this.toggleDrawer.bind(this)}
    //   >
    //     <List>
    //       {/* <ListItem button key='About'> */}
    //       <img
    //         src={closeIcon}
    //         onClick={() => {
    //           this.setState({ menuTop: false });
    //         }}
    //         className={classes.closeIconPosition}
    //       />
    //       {/* </ListItem> */}
    //       <a href='/aboutUs'>
    //         <ListItem button key='About' className={classes.aboutWidth}>
    //           <ListItemText
    //             primary={<p style={{ fontSize: '0.8rem !important' }}>About</p>}
    //           />
    //         </ListItem>
    //       </a>
    //       {[
    //         { title: 'Media Center', url: '/media-center' },
    //         { title: 'Contact Us', url: '/contact-us' },
    //       ].map((item, index) => (
    //         <a href={item.url}  key={index} >
    //           <ListItem button className={classes.aboutWidth}>
    //             <ListItemText
    //               primary={
    //                 <p style={{ fontSize: '0.8rem !important' }}>
    //                   {item.title}
    //                 </p>
    //               }
    //             />
    //           </ListItem>
    //         </a>
    //       ))}
            
    //         <ListItem button key='search' className={classes.searchWidth}>
    //         <input
    //           className={classes.searchInput}
    //           type='text'
    //           placeholder='search'
    //         />
    //       </ListItem>
    //       <ListItem button key='toll_free' className={classes.tollfreeWidth}>
    //         <ListItemText
    //           style={{ fontSize: '0.8rem !important' }}
    //           primary={
    //             <p style={{ fontSize: '0.8rem !important' }}>
    //               TOLL FREE - 1800 425 2255/ 1800 102 4477
    //             </p>
    //           }
    //         />
    //       </ListItem>
    //       <ListItem button key='toll_free' style={{marginTop:'0px'}} className={classes.tollfreeWidth}>
    //       <div className={classes.iconList}>
    //                 <a href='https://www.facebook.com/StarHealth?fref=ts'>
    //                   <i className='fa fa-facebook-square' />
    //                 </a>
    //                 <a href='https://www.linkedin.com/company/star-health-and-allied-insurance-co--ltd'>
    //                   <i className='fa fa-linkedin-square' />
    //                 </a>
    //                 <a href='http://www.youtube.com/user/StarhealthInsurance'>
    //                   <i className='fa fa-youtube-play' />
    //                 </a>
    //               </div>
    //               </ListItem>
    //     </List>
    //   </div>
    // );

    return (
      <InstantBuyLayout noFooter={true} noHeader={true}>
        <div
          style={{
            background: 'white',
            height: '100%',
            width: '100%',
          }}
        >
          {/* <Hidden mdUp>
            <React.Fragment style={{ width: '44%' }}>
              <Drawer
                anchor='right'
                open={this.state.menuTop}
                onClose={this.toggleDrawer.bind(this)}
              >
                {list}
              </Drawer>
            </React.Fragment>
          </Hidden> */}
          <div
            style={{
              borderBottom: 'solid 2px #344985 ',
              width: '100%',
            }}
          >
            <div
              className={classes.headerPosition}
            >
              <div>
                <a href={process.env.BASE_URL} target='_self'>
                  <img
                    style={{ width: `fit-content` }}
                    src={logo}
                    alt='cardiac-care-logo'
                    className={classes.logoPositionStyle}
                  />
                </a>
              </div>
              <div
                  className={classes.tollfreeposition}
                >
                  <p style={{ fontSize: `11px`,fontWeight:'400', color:'black'}}>
                  Sales & Services - <CallComponent color={'black'} contactNumber="044 4674 5800"/> 
                  </p>
                </div>
              {/* <Hidden mdUp>
                <img
                  style={{
                    width: '25px',
                    height: '25px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginLeft: 'auto',
                    cursor: 'pointer',
                    marginRight: '4%',
                  }}
                  onClick={() => {
                    this.setState({ menuTop: true });
                  }}
                  src={menuIcon}
                  alt='cardiac-care-logo'
                />
              </Hidden>
              <Hidden mdDown>
                <div
                  style={{
                    fontSize: '11px',
                    float: 'left',
                    color: `#969696`,
                    width:'100%',
                    marginTop: `32px`,
                    marginLeft: `114px`,
                    display: `inline-flex`,
                  }}
                >
                  <p style={{ fontSize: `11px` }}>
                    TOLL FREE - 1800 425 2255/ 1800 102 4477
                  </p>
                  <ul className={classes.topListStyle}>
                    <li>
                      <a href='/aboutUs' className={classes.anchorunderline}>
                        About
                      </a>
                    </li>
                    <li>|</li>
                    <li>
                      <a
                        href='/media-center'
                        className={classes.anchorunderline}
                      >
                        Media Center
                      </a>
                    </li>
                    <li>|</li>
                    <li>
                      <a href='/contact-us' className={classes.anchorunderline}>
                        Contact us
                      </a>
                    </li>
                  </ul>
                  <input
                    type='text'
                    placeholder='search'
                    className={classes.searchBox}
                  />
                  <div className={classes.iconList}>
                    <a href='https://www.facebook.com/StarHealth?fref=ts'>
                      <i className='fa fa-facebook-square' />
                    </a>
                    <a href='https://www.linkedin.com/company/star-health-and-allied-insurance-co--ltd'>
                      <i className='fa fa-linkedin-square' />
                    </a>
                    <a href='http://www.youtube.com/user/StarhealthInsurance'>
                      <i className='fa fa-youtube-play' />
                    </a>
                  </div>
                </div>
              </Hidden> */}
            </div>
          </div>
          <div className={classes.headerdiv}>
            <div className={classes.titledivstyle}>
              <div className={classes.titilestyle}>
              Star Health insurance for Corporates
              </div>
              <div className={classes.subtitle}>
                Health Insurance for Corporates.
              </div>
            </div>
            <img src={headerImage} className={classes.backgroundImage} />
          </div>
          <div className={classes.list1divstyle}>
            <div className={classes.list1header}>
              Health Insurance for Corporate Clients
            </div>
            <div>
              <ul
                className={classes.unorderlist}
                // style={{
                //   listStyleImage: ,
                // }}
              >
                {list1.map((item, index) => (
                  <li
                    key={index}
                    className={`
                    ${
                      index > 0
                        ? classes.marginLeftAlign
                        : classes.marginLeftNotAlign
                    }
                    ${classes.liststyle}`}
                  >
                    <div className={classes.icondiv}>
                      <img src={gmcListicon} style={{ marginTop: '-10px' }} />
                    </div>
                    <div
                      style={{
                        float: 'left',
                        width: '85%',
                        fontSize: '13px',
                        marginLeft: '-5px',
                        fontWeight: '500',
                      }}
                    >
                      {item}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={classes.inputdivstyle}>
            <form>
              <div style={{paddingBottom: '20px',
                paddingTop: '23px',
                color: '#243880',
                fontSize: '17px',
                fontWeight: '500',
                paddingLeft:'1.5%'}}>Request a Call Back</div>
              <div className={classes.inputcontainerstyle}>
                <div className={classes.inputheaderdiv}>
                  <div className={classes.inputdiv}>
                    <img
                      src={icons1}
                      style={{
                        width: '50px',
                        height: '50px',
                      }}
                    />
                    <TextField
                      name='name'
                      value={this.state.user.name.value}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      id='standard-basic'
                      label='Name* :'
                      InputProps={{ disableUnderline: true }}
                      className={classes.textfield}
                    />
                  </div>
                  {this.state.user.name.error === true &&
                    this.state.finalerror === true && (
                      <div className={classes.alertdiv}>
                        <img
                          src={alertIcon}
                          style={{ width: '20px', height: '18px' }}
                        />
                        {this.state.user.name.errormsg}
                      </div>
                    )}
                </div>
                <div className={classes.inputheaderdiv}>
                  <div className={classes.inputdiv}>
                    <img
                      src={icons2}
                      style={{ width: '50px', height: '50px' }}
                    />
                    <TextField
                      name='mobile'
                      value={this.state.user.mobile.value}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      id='standard-basic'
                      label='Mobile* :'
                      inputProps={{ maxLength: 10 }}
                      InputProps={{ disableUnderline: true }}
                      className={classes.textfield}
                    />
                  </div>
                  {this.state.user.mobile.error === true &&
                    this.state.finalerror === true && (
                      <div className={classes.alertdiv}>
                        <img
                          src={alertIcon}
                          style={{ width: '20px', height: '18px' }}
                        />
                        {this.state.user.mobile.errormsg}
                      </div>
                    )}
                </div>
                <div className={classes.inputheaderdiv}>
                  <div className={classes.inputdiv}>
                    <img
                      src={icons3}
                      style={{ width: `50px`, height: `50px` }}
                    />
                    <TextField
                      name='email'
                      value={this.state.user.email.value}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      id='standard-basic'
                      label='Email* :'
                      InputProps={{ disableUnderline: true }}
                      className={classes.textfield}
                    />
                  </div>
                  {this.state.user.email.error === true &&
                    this.state.finalerror === true && (
                      <div className={classes.alertdiv}>
                        <img
                          src={alertIcon}
                          style={{ width: '20px', height: '18px' }}
                        />
                        {this.state.user.email.errormsg}
                      </div>
                    )}
                </div>
                <div className={classes.inputheaderdiv}>
                  <div className={classes.inputdiv}>
                    <img
                      src={icons5}
                      style={{ width: '50px', height: '50px' }}
                    />
                    <TextField
                      name='company_website'
                      value={this.state.user.company_website.value}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      id='standard-basic'
                      label='Company Website :'
                      InputProps={{ disableUnderline: true }}
                      className={classes.textfield}
                    />
                  </div>
                </div>
                <div className={classes.inputheaderdiv}>
                  <div className={classes.inputdiv}>
                    <img
                      src={icons4}
                      style={{ width: '50px', height: '50px' }}
                    />
                    <TextField
                      id='standard-basic'
                      name='company_name'
                      value={this.state.user.company_name.value}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      label='Company Name* :'
                      InputProps={{ disableUnderline: true }}
                      className={classes.textfield}
                    />
                  </div>
                  {this.state.user.company_name.error === true &&
                    this.state.finalerror === true && (
                      <div className={classes.alertdiv}>
                        <img
                          src={alertIcon}
                          style={{ width: '20px', height: '18px' }}
                        />
                        {this.state.user.company_name.errormsg}
                      </div>
                    )}
                </div>
                <div className={classes.inputheaderdiv}>
                  <div className={classes.inputdiv}>
                    <img
                      src={icons6}
                      style={{ width: '50px', height: '50px' }}
                    />
                    <TextField
                      id='standard-basic'
                      label='Industry :'
                      name='industry'
                      value={this.state.user.industry.value}
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      InputProps={{ disableUnderline: true }}
                      className={classes.textfield}
                    />
                  </div>
                </div>
                <div className={classes.inputheaderdiv}>
                  <div className={classes.inputdiv}>
                    <img
                      src={icons7}
                      style={{ width: '50px', height: '50px' }}
                    />
                    <select
                      name='no_of_employee'
                      className={classes.inputstyle}
                      value={this.state.No_of_Employees}
                      title='Please select No of Employee'
                      id='no_of_employee'
                      onChange={(event) => {
                        this.handleChange(event);
                        this.onChange(event);
                      }}
                      // value={this.state.user.city.value}
                    >
                      <option selected='selected' value=''>
                        Select No of Employees
                      </option>
                      {optionValue.map((optionValue) => (
                        <option key={optionValue} value={optionValue}>
                          {optionValue}
                        </option>
                      ))}
                    </select>
                  </div>
                  {this.state.user.no_of_employee.error === true &&
                    this.state.finalerror === true && (
                      <div className={classes.alertdiv}>
                        <img
                          src={alertIcon}
                          style={{ width: '20px', height: '18px' }}
                        />
                        {this.state.user.no_of_employee.errormsg}
                      </div>
                    )}
                </div>
                <div className={classes.privacystyle}>
                  
                  <Hidden smDown>
                    <>
                  <div>
                    <div className={classes.checkboxdiv}>
                      <input
                        type='checkbox'
                        name='checkbox'
                        title='Please accept terms and conditions'
                        id='checkbox'
                        onChange={this.handleCheck}
                        checked={this.state.check}
                        // style={{ marginTop: '-1%' }}
                      />
                    </div>
                  </div>
                <div className={classes.privacytextdiv}>
                    <div className={classes.privacydivstyle}>
                      I have read and agree to the{' '}
                      <a
                        href='/static-privacy-policy'
                        target='_blank'
                        style={{
                          cursor: `pointer`,
                          textDecoration: 'underline',
                          margin: `0px 3px`,
                        }}
                        rel={`noopener noreferrer`}
                      >
                        Privacy Policy
                      </a>{' '}
                      I authorize Star Health Insurance to contact me via email
                      or phone or SMS
                    </div>
                    {this.state.check === false &&
                      this.state.finalerror === true && (
                        <div
                          className={classes.alertdiv}
                          style={{ textAlign: 'right' }}
                        >
                          <img
                            src={alertIcon}
                            style={{ width: '20px', height: '18px' }}
                          />
                          Please Accept terms and conditions
                        </div>
                      )}
                  </div>
                  </>
                </Hidden>
                <Hidden mdUp>
                  <GridContainer>                  
                <GridItem sm={12} xs={12} className={classes.submitdiv} style={{textAlign:'center'}}>
                <div>
                    <div className={classes.checkboxdiv}>
                      <input
                        type='checkbox'
                        name='checkbox'
                        title='Please accept terms and conditions'
                        id='checkbox'
                        onChange={this.handleCheck}
                        checked={this.state.check}
                        // style={{ marginTop: '-1%' }}
                      />
                    </div>
                  </div>
                <div className={classes.privacytextdiv}>
                    <div className={classes.privacydivstyle}>
                      I have read and agree to the{' '}
                      <a
                        href='/static-privacy-policy'
                        target='_blank'
                        style={{
                          cursor: `pointer`,
                          textDecoration: 'underline',
                          margin: `0px 3px`,
                        }}
                        rel={`noopener noreferrer`}
                      >
                        Privacy Policy
                      </a>{' '}
                      I authorize Star Health Insurance to contact me via email
                      or phone or SMS
                    </div>
                    {this.state.check === false &&
                      this.state.finalerror === true && (
                        <div
                          className={classes.alertdiv}
                          style={{ textAlign: 'right' }}
                        >
                          <img
                            src={alertIcon}
                            style={{ width: '20px', height: '18px' }}
                          />
                          Please Accept terms and conditions
                        </div>
                      )}
                  </div>
                </GridItem>
                </GridContainer>
                </Hidden>

                <Hidden smDown>

                <div className={classes.submitdiv}>
                  <input
                    type='submit'
                    className={classes.submitPosition}
                    onClick={(e) => {
                      this.handleSubmit(e);
                    }}
                  />
                </div>
                </Hidden>
                </div>
                
                <Hidden mdUp>
                  <GridContainer>
                    {/* <GridItem sm={0} xs={4} /> */}
                  
                    <GridItem sm={12} xs={12} className={classes.submitdivGrid} style={{textAlign:'center'}} >
                      <input
                        type='submit'
                        className={classes.submitPosition}
                        onClick={(e) => {
                          this.handleSubmit(e);
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </Hidden>
              </div>
            </form>
          </div>

          <Hidden smDown style={{ color: '#969696' }}>
            <div className={classes.footer}>
              <div
                style={{
                  lineHeight: '16px',
                  paddingTop: '5%',
                  color: '#969696',
                }}
              >
                <div className={classes.list1div}>
                  <ul className={classes.unorderliststyle}>
                    {Object.keys(linkname).map(
                      (keyName, i) =>
                        i <= 5 && (
                          <li key={i} className={classes.list2style}>
                            <a
                              className={classes.anchorcolor}
                              href={linkname[keyName].url}
                              target={
                                linkname[keyName].linkname
                                  ? linkname[keyName].linkname
                                  : ''
                              }
                            >
                              {linkname[keyName].name}
                            </a>
                          </li>
                        )
                    )}
                  </ul>
                </div>
                <div className={classes.list2div}>
                  <ul className={classes.unorderliststyle}>
                    {Object.keys(linkname).map(
                      (keyName, i) =>
                        i > 5 &&
                        i <= 11 && (
                          <li key={i} className={classes.list2style}>
                            <a
                              className={classes.anchorcolor}
                              href={linkname[keyName].url}
                              target={
                                linkname[keyName].linkname
                                  ? linkname[keyName].linkname
                                  : ''
                              }
                            >
                              {linkname[keyName].name}
                            </a>
                          </li>
                        )
                    )}
                  </ul>
                </div>
                <div className={classes.list3div}>
                  <ul className={classes.unorderliststyle}>
                    {Object.keys(linkname).map(
                      (keyName, i) =>
                        i > 11 &&
                        i <= 17 && (
                          <li key={i} className={classes.list2style}>
                            <a
                              className={classes.anchorcolor}
                              href={linkname[keyName].url}
                              target={
                                linkname[keyName].linkname
                                  ? linkname[keyName].linkname
                                  : ''
                              }
                            >
                              {linkname[keyName].name}
                            </a>
                          </li>
                        )
                    )}
                  </ul>
                </div>
                <div className={classes.list4div}>
                  <ul className={classes.unorderliststyle}>
                    {Object.keys(linkname).map(
                      (keyName, i) =>
                        i > 17 &&
                        i <= 23 && (
                          <li key={i} className={classes.list2style}>
                            <a
                              className={classes.anchorcolor}
                              href={linkname[keyName].url}
                              target={
                                linkname[keyName].linkname
                                  ? linkname[keyName].linkname
                                  : ''
                              }
                            >
                              {linkname[keyName].name}
                            </a>
                          </li>
                        )
                    )}
                  </ul>
                </div>
                <div className={classes.list5div}>
                  <ul className={classes.unorderliststyle}>
                    {Object.keys(linkname).map(
                      (keyName, i) =>
                        i > 23 &&
                        i <= 28 && (
                          <li key={i} className={classes.list2style}>
                            <a
                              className={classes.anchorcolor}
                              href={linkname[keyName].url}
                              target={linkname[keyName].linkname}
                            >
                              {linkname[keyName].name}
                            </a>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              </div>
              <div className={classes.footerparagraphstyle}>
                <div>
                  Registered office: No.1, New Tank Street, Valluvarkottam High
                  Road, Nungambakkam, Chennai
                </div>
                <div>
                  CIN :L66010TN2005PLC056649 | Ph: 044-28288800 | Fax:
                  044-28260062 | Email: info @ starhealth.in | Website:
                  <a
                    className={classes.anchorcolor}
                    href='https://www.starhealth.in/'
                  >
                    www.starhealth.in
                  </a>{' '}
                  | IRDAI Registration No: 129
                </div>
                <div
                  style={{
                    paddingTop: '0.8',
                    paddingBottom: '0.8%',
                    color: '#969696',
                  }}
                >
                  Insurance is the subject matter of solicitation. For more
                  details on risk factors, terms and conditions please read the
                  sales brochure carefully before concluding a sale.
                </div>
                <div>
                  IRDAI clarifies to public that:
                  <div>
                    {list2.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </div>
                </div>
                <div className={classes.footerpaydiv1}>
                  <a href='#'>
                    <img
                      src={americancard}
                      style={{ width: '49px', height: 'auto' }}
                    />
                  </a>
                  <a href='#'>
                    <img
                      src={maestrocard}
                      style={{
                        width: '49px',
                        height: 'auto',
                        marginLeft: '10px',
                      }}
                    />
                  </a>
                  <a href='#'>
                    <img
                      src={mastercard}
                      style={{
                        width: '49px',
                        height: 'auto',
                        marginLeft: '10px',
                      }}
                    />
                  </a>
                  <a href='#'>
                    <img
                      src={visacard}
                      style={{
                        width: '49px',
                        height: 'auto',
                        marginLeft: '10px',
                      }}
                    />
                  </a>
                </div>
                <div className={classes.footerpaydiv2}>
                  <a href='https://apps.apple.com/us/app/star-power/id1477621177'>
                    <img
                      src={applestore}
                      style={{ width: '82px', height: 'auto' }}
                    />
                  </a>
                  <a href='https://appworld.blackberry.com/webstore/content/21905025/?lang=en&countrycode=IN'>
                    <img
                      src={bbstore}
                      style={{
                        width: '82px',
                        height: 'auto',
                        marginLeft: '10px',
                      }}
                    />
                  </a>
                  <a href='https://play.google.com/store/apps/details?id=com.star.customer_app'>
                    <img
                      src={appstore}
                      style={{
                        width: '82px',
                        height: 'auto',
                        marginLeft: '10px',
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </Hidden>

          <Hidden mdUp>
            <div  style={{background:'#F7F7F7',
                          paddingLeft:'6%',
                          paddingTop:'5%',
                          paddingBottom: '5%'}}>
            {/* <div
              style={{
                lineHeight: '16px',
                paddingTop: '5%',
                color: '#969696',
              }}
            >
              <div className={classes.list1div}>
                <ul className={classes.unorderliststyle}>
                  {Object.keys(linkname).map(
                    (keyName, i) =>
                      i <= 14 && (
                        <li key={i} className={classes.list2style}>
                          <a
                            className={classes.anchorcolor}
                            href={linkname[keyName].url}
                            target={
                              linkname[keyName].linkname
                                ? linkname[keyName].linkname
                                : ''
                            }
                          >
                            {linkname[keyName].name}
                          </a>
                        </li>
                      )
                  )}
                </ul>
              </div>
              <div className={classes.list2div}>
                <ul className={classes.unorderliststyle}>
                  {Object.keys(linkname).map(
                    (keyName, i) =>
                      i > 14 &&
                      i <= 28 && (
                        <li key={i} className={classes.list2style}>
                          <a
                            className={classes.anchorcolor}
                            href={linkname[keyName].url}
                            target={
                              linkname[keyName].linkname
                                ? linkname[keyName].linkname
                                : ''
                            }
                          >
                            {linkname[keyName].name}
                          </a>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </div> */}
            <div className={classes.footerpaydiv3}>
              <a href='#'>
                <img
                  src={americancard}
                  style={{ width: '49px', height: 'auto' }}
                />
              </a>
              <a href='#'>
                <img
                  src={maestrocard}
                  style={{
                    width: '49px',
                    height: 'auto',
                    marginLeft: '10px',
                  }}
                />
              </a>
              <a href='#'>
                <img
                  src={mastercard}
                  style={{
                    width: '49px',
                    height: 'auto',
                    marginLeft: '10px',
                  }}
                />
              </a>
              <a href='#'>
                <img
                  src={visacard}
                  style={{
                    width: '49px',
                    height: 'auto',
                    marginLeft: '10px',
                  }}
                />
              </a>
            </div>
            <div className={classes.footerpaydiv4}>
              <a href='https://apps.apple.com/us/app/star-power/id1477621177'>
                <img
                  src={applestore}
                  style={{ width: '82px', height: 'auto' }}
                />
              </a>
              <a href='https://appworld.blackberry.com/webstore/content/21905025/?lang=en&countrycode=IN'>
                <img
                  src={bbstore}
                  style={{
                    width: '82px',
                    height: 'auto',
                    marginLeft: '10px',
                  }}
                />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.star.customer_app'>
                <img
                  src={appstore}
                  style={{
                    width: '82px',
                    height: 'auto',
                    marginLeft: '10px',
                  }}
                />
              </a>
            </div>
            <div className={classes.copyrightFooter}>
              Copyright ©2016. Star Insurance. All rights reserved
            </div>
            </div>
          </Hidden>
        </div>
      </InstantBuyLayout>
    );
  }
}

GmcAllPolicies.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(GmcAllPoliciesStyle)(GmcAllPolicies);
