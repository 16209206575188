import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import Phone from "@material-ui/icons/Phone";
// core components
import headerStyle from "assets/jss/material-kit-pro-react/components/headerStyle.jsx";
import { Link } from "gatsby";
import { browserWindow } from "util/localStorageHelper";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      showLinks: true
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }
  prevScrollPos = browserWindow.pageYOffset;
  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }
  render() {
    const { classes, color, brand, absolute, topNav, small } = this.props;
    const appBarClasses = classNames({
      [classes.absolute]: absolute,
      // [classes.topNav]: topNav,
      [classes.setMaxHeight]: small,
      [classes.justFixed]: true,
    });
    return (
      <div>
        <AppBar
          className={appBarClasses}
          style={{ padding: "0", backgroundColor: "white" }}
        >
          <Toolbar>{brand && brand}</Toolbar>
        </AppBar>
      </div>
    );
  }
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  brand: PropTypes.node,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the browserWindow.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

export default withStyles(headerStyle)(Header);
